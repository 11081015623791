import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import data from "@sourceflow/client_reviews.json";

export const useClientReviews = ({ limit = null } = {}) => {
  let items = useSourceFlowCollection(data);

  if (limit) {
    items = items.slice(0, limit);
  }

  return items;
};

export default useClientReviews;
