import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import data from "@sourceflow/candidate_reviews.json";

export const useCandidateReviews = ({ limit = null } = {}) => {
  let items = useSourceFlowCollection(data);

  if (limit) {
    items = items.slice(0, limit);
  }

  return items;
};

export default useCandidateReviews;
