import useCandidateReviews from "@/hooks/useCandidateReviews";
import useClientReviews from "@/hooks/useClientReviews";
import useStaffReviews from "@/hooks/useStaffReviews";
import { Carousel } from "@/ui/Carousel";
import { ParallaxBackground } from "@/ui/ParallaxBackground";
import { ReviewCard } from "@/ui/ReviewCard";
import cn from "classnames";
import { useMemo, useState } from "react";
import { Button, ButtonGroup, Container, Stack } from "react-bootstrap";

import bg_1 from "./bg_1.webp";
import bg_2 from "./bg_2.webp";
import QuoteIcon from "./QuoteIcon.svg";
import classes from "./styles.module.scss";

export default function ({
  className = "py-5 py-md-7",
  title,
  description,
  types = ["client", "candidate"],
  prefix,
  EditableText,
  EditableHtml,
  theme = "light",
}) {
  const data = useMemo(
    () => [
      {
        key: "client",
        title: "Client Reviews",
        items: useClientReviews({ limit: 12 }),
      },
      {
        key: "candidate",
        title: "Candidate Reviews",
        items: useCandidateReviews({ limit: 12 }),
      },
      {
        key: "staff",
        title: "HRS Staff Reviews",
        items: useStaffReviews({ limit: 12 }),
      },
    ],
    [],
  );
  const [active, setActive] = useState(types[0]);

  return (
    <div className={cn(className, classes.ReviewsCarousels)} data-bs-theme={theme}>
      <ParallaxBackground anim="y1:x0:z0" image={bg_1} className={classes.bg_1} />
      <ParallaxBackground anim="y2:x1:z0" image={bg_2} className={classes.bg_2} />
      <Container fluid="md" className="mw-xl text-center mb-4">
        <EditableText path="title" tag="h2">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </EditableText>
        <EditableHtml path="description">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </EditableHtml>
      </Container>
      <Container className="d-flex justify-content-center">
        <Stack className="flex-column align-items-center">
          {types.length > 1 && (
            <ButtonGroup className="mb-5">
              {data
                .filter(({ key }) => types.includes(key))
                .map(({ key, title }) => (
                  <Button
                    key={key}
                    active={key === active}
                    variant={null}
                    onClick={() => setActive(key)}
                  >
                    {title}
                  </Button>
                ))}
            </ButtonGroup>
          )}
          <img src={QuoteIcon} alt="" className="icon" />
        </Stack>
      </Container>
      {data
        .filter(({ key }) => key === active)
        .map(({ key, items }) => (
          <Carousel
            key={key}
            elementKey={`${prefix}_${key}`}
            navigation={false}
            centeredSlides={true}
            spaceBetween={0}
            pagination={true}
            slidesPerView={1.2}
            breakpoints={{
              480: { slidesPerView: 1.6 },
              768: { slidesPerView: 1.8 },
              992: { slidesPerView: 2.4 },
              1200: { slidesPerView: 3 },
              1400: { slidesPerView: 3.4 },
            }}
            items={[...items, ...items].map((review, idx) => (
              <ReviewCard
                key={idx}
                {...review}
                image={key === "staff" ? review.staff_image : null}
                rating={key !== "staff" ? review.rating : null}
              />
            ))}
          />
        ))}
    </div>
  );
}
