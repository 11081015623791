import useSourceFlowCollection from "@/hooks/useSourceFlowCollection";
import data from "@sourceflow/hrs_staff_reviews.json";

export const useStaffReviews = ({ limit = null } = {}) => {
  let items = useSourceFlowCollection(data);

  if (limit) {
    items = items.slice(0, limit);
  }

  return items;
};

export default useStaffReviews;
